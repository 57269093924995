import React from "react"
import GoogleFonts from "../components/GoogleFonts"

const content = {
  logo: require("../assets/svg/logos/jd-logo-10.svg"),
  logoAlt: "Logo Jubarte Design",
  title: "Page Not Found",
  pageSubtitle: "Ohhh nooo!",
  text1: "Looks like you got lost in uncharted waters.",
  text2: "We are sorry that this is not the dive of your dreams...",
  text3: "Let us guide you back to safe waters.",
  text4: "And, if you still can't find what you're looking for, please contact us",
  lighthouseAltText: "Lighthouse icon",
  lighthouseImage: require("../assets/svg/flat-icons/jd-icons-lighthouse.svg"),
  goBackButton: "Send me back",
}

const NotFoundPage = () => (
  <>
    <GoogleFonts />

    <main id="page-not-found-content" role="main">
      <div class="container u-space-1">
        <div class="col-lg-12 col-xl-12 text-lg-center px-0" style={{ textAlign: "center" }}>
          <a class="d-inline-block p-3 p-sm-5 align-items-center" href="/" aria-label={content.logoAlt}>
            <img
              class="d-none d-lg-inline-block"
              src={content.logo}
              alt={content.logoAlt}
              style={{ width: "440px" }}
            ></img>
            <img
              class="d-inline-block d-lg-none"
              src={content.logo}
              alt={content.logoAlt}
              style={{ width: "240px" }}
            ></img>
          </a>
        </div>
      </div>

      <div class="container">
        <div class="position-relative">
          <div class="container u-space-1">
            <div class="row align-items-center">
              <div class="col-lg-8 mb-9 mb-lg-0 jd-text-block">
                <div class="pr-lg-1 mb-7">
                  <h1 class="jd-text-error">404</h1>
                  <h3>{content.title}</h3>
                  <br />
                  <h3>
                    <span class="font-weight-bold">{content.pageSubtitle}</span>
                  </h3>
                  <br />
                  <h6>{content.text1}</h6>
                  <h6>{content.text2}</h6>
                  <h6>{content.text3}</h6>
                  <h6>{content.text4}</h6>
                </div>
              </div>

              <div class="col-lg-4 position-relative jd-lighthouse-icon">
                <img
                  class="d-none d-lg-inline-block"
                  src={content.lighthouseImage}
                  alt={content.lighthouseAltText}
                  style={{ maxWidth: "100%" }}
                />
              </div>

              <div class="container u-space-1 text-left">
                <a href="/" class="btn jd-btn-primary--air u-btn-wide rounded-pill">
                  {content.goBackButton}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </>
)

export default NotFoundPage
